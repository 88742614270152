import { cookies } from "../packs/cookies";
import { webflowJS } from "../packs/webflow";
import { Menu } from "../packs/menu";
import { Popup } from "../packs/event-popup";
import { Toggle } from "../packs/toggle";
import { Splash } from "../packs/splash";
import { flashFade } from "../packs/flash-fade";
import { SimpleParallax } from "../packs/simple-parallax";
import { FacesFadeIn } from "../packs/faces";
import { SocialShare } from "../packs/social-share";
import { BlogNav } from "../packs/blog-nav";
import { Noise } from "../packs/noise";
import { mgGlitch } from "../packs/mgGlitch.min";
import { GlitchJs } from "../packs/glitch";
import { Modal } from "../packs/modal";
import { CustomSwiper } from "../packs/customSwiper";

cookies();
webflowJS();
Menu();
Popup();
Toggle();
flashFade();
SimpleParallax();
FacesFadeIn();
SocialShare();
BlogNav();
Noise();
mgGlitch();
GlitchJs();
Splash();
Modal();
CustomSwiper();
